import axios from 'axios'
import { partnerStore } from '../stores/partnerStore'
// import {kbStore} from '../stores/kbStore';
import globals from '../globals'
import { memberStore } from '../stores/memberStore'

async function setCompany(name, url) {
  const data = { name, url }
  let resourceUrl = globals.apiHostBot + '/set'
  try {
    let response = await axios.post(resourceUrl, data)
    return response.data
  } catch (e) {
    console.log('e! ', e)
    throw e
  }
}

async function getCompany() {
  let url = globals.apiHostBot + '/get'
  try {
    let response = await axios.get(url)
    return response.data
  } catch (e) {
    console.log('e! ', e)
    throw e
  }
}

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

async function sendChat(message) {
  const data = { 
    message,
    session_id: memberStore.member.guestId
  }
  let url = globals.apiHostBot + '/chat'
  try {
    let response = await axios.post(url, data)

    return response.data
  } catch (e) {
    console.log('e! ', e)
    throw e
  }
}

async function setRole(role) {
  const data = { role }
  let url = globals.apiHostBot + '/updateRole'
  try {
    let response = await axios.post(url, data)
    return response.data
  } catch (e) {
    console.log('e! ', e)
    throw e
  }
}

export { setCompany, getCompany, sendChat, setRole }
