import { DateTime } from 'luxon'
// utils/markupParser.js
import MarkdownIt from 'markdown-it'
import DOMPurify from 'dompurify'
import linkifyHtml from 'linkify-html'

import html2canvas from 'html2canvas'

const formatTimestamp = timestamp => {
  if (timestamp) {
    let formattedDiff
    let timeFormat = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    const dateObj = new Date(timestamp)

    const start = DateTime.fromJSDate(dateObj)
    const end = DateTime.now()
    const diffInMinutes = end.diff(start, 'minutes').toObject()
    const diffInHours = end.diff(start, 'hours').toObject()
    const diffInDays = end.diff(start, 'days').toObject()

    if (diffInMinutes.minutes <= 60) {
      formattedDiff = parseInt(diffInMinutes.minutes) + 'm'
    } else if (diffInHours.hours <= 24) {
      formattedDiff = parseInt(diffInHours.hours) + 'h'
    } else if (diffInDays.days <= 30) {
      timeFormat.month = 'short'
      timeFormat.day = 'numeric'
      formattedDiff = parseInt(diffInDays.days) + 'd'
    } else {
      timeFormat.month = 'short'
      timeFormat.day = 'numeric'
      timeFormat.year = 'numeric'
    }

    const formattedTimestamp = dateObj.toLocaleString('en-US', timeFormat)
    return formattedDiff
      ? formattedTimestamp + ` (${formattedDiff} ago)`
      : formattedTimestamp
  } else {
    return ''
  }
}

const getCookieOptions = ttl => {
  if (!ttl || ttl < 0) ttl = 2592000
  var date = new Date()
  date.setTime(date.getTime() + ttl * 1000)
  return {
    samesite: 'None',
    expires: date,
    secure: true,
  }
}

// https://boomtown.atlassian.net/browse/DEV-4787
// dates from GET v2/issues are in PST, but do not have a TZ in the string
// use Luxon to create a date with the correct TZ
// UPDATE - dates now in UTC, now adding TZ to the string
const addTZtoDateTimeString = date => {
  // Convert "2025-01-17 04:42:33" to "2025-01-17T04:42:33Z"
  const isoString = date.replace(' ', 'T') + 'Z'
  return DateTime.fromISO(isoString)
}

// Function to post-process links
function addTargetBlankToLinks(html) {
  const div = document.createElement('div')
  div.innerHTML = html
  const links = div.querySelectorAll('a')
  links.forEach(link => {
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
  })
  return div.innerHTML
}

function parseMarkup(input) {
  // Helper function to check if text contains markdown
  const containsMarkdown = text => {
    const markdownPatterns = [
      /[*_~`]/, // Basic markdown syntax
      /^\s*[-+*]\s/, // List items
      /^\s*#{1,6}\s/, // Headers
      /\[.*?\]\(.*?\)/, // Links
      /^\s*>\s/, // Blockquotes
      /^\s*```/, // Code blocks
    ]
    return markdownPatterns.some(pattern => pattern.test(text))
  }

  if (containsMarkdown(input)) {
    const markdownParser = new MarkdownIt()
    const html = markdownParser.render(input)
    const purifiedHtml = DOMPurify.sanitize(html)
    const linkifiedHtml = addTargetBlankToLinks(purifiedHtml)
    return linkifiedHtml
  } else {
    const linkified = linkifyHtml(input, {
      attributes: {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    })
    const purifiedHtml = DOMPurify.sanitize(linkified)
    const linkifiedHtml = addTargetBlankToLinks(purifiedHtml)
    return linkifiedHtml
  }
}

async function saveDivAsImage(divId) {
  const element = document.getElementById(divId)

  // Get the full dimensions of the element
  const elementWidth = element.scrollWidth
  const elementHeight = element.scrollHeight

  return html2canvas(element, {
    scale: 1.5, // Adjust to control resolution
    width: elementWidth,
    height: elementHeight,
    windowWidth: elementWidth,
    windowHeight: elementHeight,
  }).then(canvas => {
    const imgData = canvas.toDataURL('image/png')
    return imgData
  })
}

function blobToFile(blob, filename) {
  // Create a new File object from the Blob
  return new File([blob], filename, { type: blob.type })
}

function base64ToFile(base64String, filename) {
  const arr = base64String.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export {
  formatTimestamp,
  getCookieOptions,
  addTZtoDateTimeString,
  parseMarkup,
  saveDivAsImage,
  blobToFile,
  base64ToFile,
}
